<script setup>
import AppRoute from 'src/router/app-route';
import { Route } from 'src/router/routes';
import { useAuthStore } from 'stores/auth-store.js';
import { computed } from 'vue';

const props = defineProps({
    inverted: {
        type: Boolean,
        default: false
    },
    noLink: {
        type: Boolean,
        default: false
    }
});

const authStore = useAuthStore();

const wrapperComponent = computed(() => props.noLink ? 'div' : 'router-link');

const wrapperComponentBind = computed(() => {
    if (props.noLink) {
        return {};
    }
    return authStore.userAuthenticated
        ? { to: AppRoute.build(Route.Dashboard) }
        : { to: AppRoute.build(Route.Home) }
});
</script>

<template>
    <div class="app-logo">
        <component :is="wrapperComponent" v-bind="wrapperComponentBind" class="no-hover-decoration">
            <img v-if="inverted" src="https://xprt.syd1.cdn.digitaloceanspaces.com/pub/xprt-logo-white.png" alt="xprt.dev" class="logo"/>
            <img v-else src="https://xprt.syd1.cdn.digitaloceanspaces.com/pub/xprt-logo-blue.png" alt="xprt.dev" class="logo"/>
        </component>
    </div>
</template>

<style lang="scss" scoped>
.app-logo {
    display: inline-block;
    line-height: 0;
    height: 42px;
    width: fit-content;

    a {
        line-height: 0;
    }

    img {
        border-radius: $generic-border-radius;
        height: 100%;
        width: auto;
    }
}
</style>
